<script setup lang="ts">
import type { FooterBanner } from "~/types/data";
import { leadCollectionModalStateKey } from "~/types/injectKeys";
import type { ModalState } from "~/types/modal";

const props = defineProps<{
  pageSlug?: string;
  hideCta?: boolean;
}>();

const { $sentry } = useNuxtApp();

const { toggleModal } = inject<ModalState>(leadCollectionModalStateKey, {
  modalOpen: ref(false),
  toggleModal: () => { },
});

const { locale } = useI18n();

const gql = useGql();

const defaultPageSlug = locale.value === "en" ? "other-options" : "diger-secenekler";

const { data, error } = await useAsyncData(
  "footer-banner",
  async () => {
    const pageData = await gql({
      operation: "GetFooterInformation",
      variables: { slug: props.pageSlug ?? defaultPageSlug },
    });

    const customfooterBanner = pageData.page?.generalPageOptions?.pageOtherOptions?.footerBanner;
    let generalfooterBanner: typeof customfooterBanner | undefined;
    const isFooterBannerComplete = customfooterBanner?.title && customfooterBanner?.image?.node?.sourceUrl && customfooterBanner?.cta;
    if (!isFooterBannerComplete && props.pageSlug !== defaultPageSlug) {
      const generalData = await gql({
        operation: "GetFooterInformation",
        variables: { slug: defaultPageSlug },
      });

      generalfooterBanner = generalData.page?.generalPageOptions?.pageOtherOptions?.footerBanner;
    }

    return {
      image: {
        url: customfooterBanner?.image?.node?.sourceUrl || generalfooterBanner?.image?.node?.sourceUrl || "",
        alt: customfooterBanner?.imageAltText || generalfooterBanner?.imageAltText || "",
      },
      title: customfooterBanner?.title || generalfooterBanner?.title || "",
      cta: customfooterBanner?.cta || generalfooterBanner?.cta || "",
    } satisfies FooterBanner;
  },
);

if (error.value) {
  $sentry.captureError(error.value);
}
</script>

<template>
  <div class="relative flex flex-col">
    <div class="relative">
      <NuxtPicture
        v-if="data?.image?.url"
        :src="data?.image?.url"
        :alt="data?.image?.alt"
        :img-attrs="{ class: 'h-72 w-full object-cover' }"
      />

      <p
        v-if="data?.image?.url"
        class="absolute inset-x-0 bottom-16 flex items-center justify-center px-4 text-center text-2xl font-semibold tracking-wider text-white lg:text-4xl"
      >
        {{ data?.title }}
      </p>

      <div
        v-if="data?.cta && !hideCta"
        class="absolute inset-x-0 -bottom-0 z-10 flex translate-y-1/2 transform justify-center"
      >
        <UButton
          variant="solid"
          size="xl"
          color="red"
          :label="data?.cta"
          class="px-8 font-semibold"
          @click="toggleModal"
        />
      </div>
    </div>
  </div>
</template>
